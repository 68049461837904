// using directly because I need fieldValue
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import "firebase/functions";

import moment from "moment";

import httpGateway from "../gateways/httpGateway";
import firebaseDbGateway from "../gateways/firebaseDbGateway";
import { checkLimits } from "../utils/firebaseUtils";
import getAbsoluteURL from "../utils/getAbsoluteURL";
import { validateUrl } from "../utils/validationHelper";
import { getParentOrgId } from "../utils/orgDataHelper";
import { decrementFeatureLimit } from "../utils/decrementFeatureLimit";
import { getCurrentFeatureLimits } from "../utils/getCurrentFeatureLimits";
import { checkFeatureLimit } from "../utils/checkFeatureLimit";

const db = firebase.firestore();

const collectionNameConstants = {
  ENGINES: "engines",
  WEBSITES: "websites",
  GA_DATA: "gaData",
};

const { ENGINES, WEBSITES, GA_DATA } = collectionNameConstants;

async function checkCollectionExists(collectionName) {
  const collectionRef = db.collection(collectionName);
  const snapshot = await collectionRef.limit(1).get();

  if (snapshot.empty) {
    console.log(`Collection ${collectionName} does not exist or is empty.`);
    return false;
  } else {
    console.log(`Collection ${collectionName} exists.`);
    return true;
  }
}

// We need to actually get the keywords instead of serps
// async function getAllWebsiteUniqueKeywords(websiteId, url, engineId) {
//   if (!url) {
//     console.log("url missing from website");
//     return [];
//   }
//   debugger;
//   let domain = new URL(url);
//   const urlHostName = domain.hostname.replace("www.", "");

//   const keywordsArr = await db
//     .collection(`websites/${websiteId}/keywords`)
//     .get();

//   // temporary unique keywords filter
//   const uniqueKeywords = {};
//   const uniqueKeywordsArr = [];

//   for (const keyword of keywordsArr.docs) {
//     uniqueKeywordsArr.push({
//       id: keyword.id,
//       ...keyword.data(),
//     });
//     // old
//     // keyword.competitors = [];
//     // keyword.keywordId = keyword.id;
//     // keyword.change = 0;
//     // if (!keyword.tags) keyword.tags = [];
//     // if (!uniqueKeywords.hasOwnProperty(keyword.id)) {
//     //   uniqueKeywords[keyword.id] = keyword;
//     //   uniqueKeywordsArr.push(keyword);
//     // }
//   }

//   const keywords = uniqueKeywordsArr.map((keyword) => {
//     return {
//       ...keyword,
//       url: urlHostName,
//       websiteId,
//     };
//   });

//   return keywords;
// }

async function getAllWebsiteUniqueKeywords(websiteId, url, engineId, startDate, endDate) {
  if (!url) {
    console.log("URL missing from website");
    return [];
  }

  // debugger;

  let domain = new URL(url);
  const urlHostName = domain.hostname.replace("www.", "");

    // Use moment to convert startDate and endDate into Firestore-compatible Timestamps
    const startTimestamp = startDate ? firebase.firestore.Timestamp.fromDate(moment(startDate).toDate()) : null;
    const endTimestamp = endDate ? firebase.firestore.Timestamp.fromDate(moment(endDate).toDate()) : null;
    
  // Query keywords for the given websiteId
  const keywordsArr = await db
    .collection(`websites/${websiteId}/keywords`)
    .get();

  // Convert the keyword documents into an array of objects
  const uniqueKeywordsArr = keywordsArr.docs.map((keyword) => ({
    id: keyword.id,
    ...keyword.data(),
  }));

  // Map to hold the final keyword data with the url and websiteId
  const keywords = uniqueKeywordsArr.map((keyword) => ({
    ...keyword,
    // url: urlHostName,
    websiteId,
  }));

  // Query SERP data and merge it into the keywords array
  await populateKeywordsWithSerps(keywords, engineId, startTimestamp, endTimestamp);

  return keywords;
}

async function populateKeywordsWithSerps(keywordsArr, engineId, startDate, endDate) {
  // Iterate over each keyword in the array
  for (let keywordObj of keywordsArr) {
    const keywordId = keywordObj.id;

    try {
      // Get the most recent SERP document
      const mostRecentSnapshot = await db.collection("engines")
        .doc(engineId)
        .collection("serps")
        .where("keywordId", "==", keywordId)
        .orderBy('rank_date', 'desc')
        .limit(1)
        .get();

      let latestSerp = null;
      if (!mostRecentSnapshot.empty) {
        latestSerp = mostRecentSnapshot.docs[0].data();
      }

      // Get the oldest SERP document
      const firstSnapshot = await db.collection("engines")
        .doc(engineId)
        .collection("serps")
        .where("keywordId", "==", keywordId)
        .orderBy('rank_date', 'asc')
        .limit(1)
        .get();

      let oldestSerp = null;
      if (!firstSnapshot.empty) {
        oldestSerp = firstSnapshot.docs[0].data();
      }

      // If both latest and oldest SERP documents are found, calculate the position change
      if (latestSerp && oldestSerp) {
        let positionChange = oldestSerp.position - latestSerp.position;

        // Update keyword object with the necessary SERP data
        keywordObj.page = latestSerp.page || 'N/A';
        keywordObj.position = latestSerp.position || 'N/A';
        keywordObj.url = latestSerp.urlFound || 'N/A';
        keywordObj.change = positionChange || 0;
      } else {
        // No matching SERPs found for this keyword
        console.log(`No SERPs found for keywordId: ${keywordId}`);
        keywordObj.page = 'N/A';
        keywordObj.position = 'N/A';
        keywordObj.url = 'N/A';
        keywordObj.change = 0;
      }
    } catch (error) {
      console.error(`Error querying SERPs for keywordId: ${keywordId}`, error);
    }
  }

  return keywordsArr;
}

// async function populateKeywordsWithSerps(keywordsArr, engineId, startDate, endDate) {
//   // Path to the engines collection and serps subcollection

//   // const serpsCollectionRef = collection(engineDocRef, 'serps');

//   // Iterate over each keyword in the array
//   for (let keywordObj of keywordsArr) {
//     const keywordId = keywordObj.id;

//     const mostRecentDoc = await db.collection("engines")
//     .doc(engineId)
//     .collection("serps")
//     .orderBy('rank_date', 'desc')
//     .limit(1)
//     .get();

//     if (!mostRecentDoc.empty) {
//         const doc = mostRecentDoc.docs[0];
//         console.log('Most recent document:', doc.data());
//     }

//     const firstDoc = await db.collection("engines")
//     .doc(engineId)
//     .collection("serps")
//     .orderBy('rank_date', 'asc')
//     .limit(1)
//     .get();

//     if (!firstDoc.empty) {
//         const doc = firstDoc.docs[0];
//         console.log('First document:', doc.data());
//     }

//     const serpsSnapshot = await db
//     .collection("engines")
//     .doc(engineId)
//     .collection("serps")
//     .where("keywordId", "==", keywordId)
//     // .where('rank_date', '>=', startDate) // Filter by startDate
//     // .where('rank_date', '<=', endDate)   // Filter by endDate
//     .orderBy("rank_date", "desc")
//     .limit(1000) // Limit to a reasonable number of SERPs
//     .get();
//     try {
//       // Query the SERPs collection for the current keywordId

//       // Get SERP documents for this keyword
//       // const serpsSnapshot = await getDocs(serpsQuery);

//       if (serpsSnapshot.empty) {
//         // No SERPs found, skip to the next keyword
//         console.log(`No SERPs found for keywordId: ${keywordId}`);
//         continue;
//       }

//       // Get the first (latest) and last (oldest) SERP documents
//       const serpsDocs = serpsSnapshot.docs;
//       let latestSerp = mostRecentDoc.data(); // The most recent SERP within the date range
//       let oldestSerp = firstDoc.data(); // The earliest SERP within the date range

//       // Calculate position change
//       let positionChange = oldestSerp.position - latestSerp.position;

//       // Add the SERP data to the keyword object
//       keywordObj.page = latestSerp.page || 'N/A';
//       keywordObj.position = latestSerp.position || 'N/A';
//       keywordObj.url = latestSerp.urlFound || 'N/A';
//       keywordObj.change = positionChange || 0; // Default to 0 if no change
//     } catch (error) {
//       console.error(`Error querying SERPs for keywordId: ${keywordId}`, error);
//     }
//   }

//   return keywordsArr;
// }

async function getNotes(websiteId, orgId) {
  let notes;
  let notesQuery = (
    await firebaseDbGateway.queryCollection("notes", [
      ["websiteId", "==", websiteId],
      ["orgId", "==", orgId],
    ])
  ).data;

  if (notesQuery.length > 0) {
    notes = notes.map((note) => {
      return {
        ...note,
        dateCreated: note.dateCreated.toDate(),
      };
    });
  } else {
    return [];
  }

  return notes;
}

/**
 * @params {string} date  - "yyyy-mm-dd HH:MM:SS +HH:MM"
 * @description converts dateString from dataforseoAPI into usable Date object
 */

function parseSerpDate(date) {
  let result = date.split(" ");
  result = `${result[0]}T${result[1]}${result[2]}`;
  return new Date(result);
}
/**
 *
 * @param {*} websiteId
 * @param {*} engine - not implemented
 * @param {*} limit - not implemented
 * @returns
 */
async function getWebsiteSerps(
  engine,
  { startDate, endDate },
  keywordId,
  currentWebsiteUrl
) {
  const serps = [];
  const engineId = engine.id;

  // console.log(
  //   `getWebsiteSerpsgetWebsiteSerpsgetWebsiteSerps startDate ${startDate}`
  // );
  // console.log(
  //   `getWebsiteSerpsgetWebsiteSerpsgetWebsiteSerps startDate ${endDate}`
  // );
  // console.log(startDate);
  // console.log(typeof startDate);
// Start date (start of the day)
    const startTest = new Date(startDate);
    startTest.setHours(0, 0, 0, 0); // Set time to 00:00:00.000 (start of the day)

    // End date (end of the day)
    const endTest = new Date(endDate);
    endTest.setHours(23, 59, 59, 999); // Set time to 23:59:59.999 (end of the day)

    // Convert JavaScript Date objects to Firestore Timestamps
const startTimestamp = firebase.firestore.Timestamp.fromDate(startTest);
const endTimestamp = firebase.firestore.Timestamp.fromDate(endTest);

  console.log(startTest);
  console.log(startTimestamp);

  // requires dates to be saved as date, not string
  // const start = moment(startDate).startOf('day').toISOString();
  // const end = moment(endDate).endOf('day').toISOString();
  let queryArr = [
    ["engineId", "==", engineId],
    // ["keywordId", "==", keywordId]
    ["rank_date", ">=", startTimestamp],
    ["rank_date", "<=", endTimestamp],
  ];

  if (keywordId) {
    queryArr = [
      ["engineId", "==", engineId],
      ["keywordId", "==", keywordId],
      ["rank_date", ">=", startTimestamp],
      ["rank_date", "<=", endTimestamp],
    ];
  }

  let collectionName = `engines/${engineId}/serps`;
  // console.log(`${keywordId} in getWebsiteSerps`)
  // TODO: Disabling due to bug showing all keyword serps in table of single keyword page with found URL matching website and not being the competitors
  // TODO: keywordId is only passed from [kid] file
  // if (keywordId) {
  //   collectionName = "competitorSerps";
  //   queryArr.push(["keywordId", "==", keywordId]);
  // }

  // console.log(`getting engineSerps for engineId ${engineId}`);

  return checkCollectionExists(collectionName)
    .then(async (exists) => {
      if (exists) {
        // Proceed with your queries
        console.log(`queryArr`);
        console.log(queryArr);

        let engineSerps;

        if (keywordId) {
          engineSerps = await db
            .collection(collectionName)
            .where("engineId", "==", engineId)
            .where("rank_date", ">=", startTimestamp)
            .where("rank_date", "<=", endTimestamp)
            .where("keywordId", "==", keywordId)
            .limit(1000)
            .orderBy("rank_date", "desc")
            .get();
        } else {
          engineSerps = await db
            .collection(collectionName)
            .where("engineId", "==", engineId)
            .where("rank_date", ">=", startTimestamp)
            .where("rank_date", "<=", endTimestamp)
            .limit(1000)
            .orderBy("rank_date", "desc")
            .get();
        }

        // debugger;
        console.log(`Engine serps `);
        console.log(engineSerps);
        console.log(engineSerps.size);
        console.log(engineSerps.empty);

        // console.log(engineSerps.docs[0].data());

        const serpDocs = engineSerps.docs;
        // console.log('currentWebsiteUrl');
        // console.log(currentWebsiteUrl);

        // TODO: get root domain of website currentWebsiteUrl, check if the urlFound includes that string and push
        // TODO: do these need to be filtered looking for serps with url matching?
        // TODO: how does this work on website overview page vs single keyword?

        if(!engineSerps.empty) {
          for (let i = 0; i < serpDocs.length; i++) {
            let serp = serpDocs[i];
  
            let serpData = serp.data();
            // serp dates are string timestamps, from dataforseo API. need to change to date.
            console.log(
              `${serp.id} serp.urlFound is ${serpData.urlFound} before extracting domain`
            );
            const cleanUrl = serpData.urlFound.trim();
            let domain = new URL(cleanUrl);
            serpData.url = domain.hostname.replace("www.", "");
  
            // serp.rank_date = parseSerpDate(new Date(serp.rank_date.seconds * 1000));
            serpData.rank_date = serpData.rank_date.seconds * 1000;
  
            // console.log(`serp.rank_date is now ${serp.rank_date}`)
  
            // let momentDate = moment(serp.rank_date);
            // const start = moment(startDate).startOf("day");
            // const end = moment(endDate).endOf("day");
  
            // if (startDate && endDate) {
            //   if (momentDate.isAfter(start) && momentDate.isBefore(end)) {
            //     // if (currentWebsiteUrl) {
            //     //   serp.urlFound.includes(currentWebsiteUrl) && serps.push(serp);
            //     // } else {
            //       serps.push(serp);
            //     // }
            //   }
            //   continue;
            // }
            serps.push(serpData);
          }
  
          console.log(`*************** return serp *******************`);
          console.log(serps);
        }

        // debugger;
        return serps || [];
        // return engineSerps;
      } else {
        // Handle the case where the collection does not exist
        return [];
      }
    })
    .catch((error) => {
      console.error("Error checking collection:", error);
    });
}

/**
 *
 * @param {*} data
 * @param {*} timeFrameUnit
 * @param {*} axisKeys {x: 'rank_date', y: 'position' } - the object keys on data entries to get x and y
 * @param {*} ParseDate: Function - optional value to transform raw x key value from data entry to Date
 * @description map an Array of objects to chartjs data aggregated by timeFrame, formatted to x and y coordinates.
 * @returns {x: Date | number, y: number}[]
 */
function mapToChartData(data, timeFrameUnit, axisKeys, parseDate) {
  console.log(`mapToChartData`);
  console.log(data);

  // Testing new function
  function calculateAveragePosition(data, timeFrameUnit) {
    // Create an object to store sum and count of positions for each time frame
    const positionSumPerTimeFrame = {};
    const positionCountPerTimeFrame = {};

    // Determine the time frame interval based on the provided unit
    const timeFrameInterval = {
      daily: 24 * 60 * 60 * 1000, // 1 day in milliseconds
      weekly: 7 * 24 * 60 * 60 * 1000, // 1 week in milliseconds
      monthly: 30 * 24 * 60 * 60 * 1000, // 1 month in milliseconds (approximate)
    }[timeFrameUnit];

    // Iterate through the data array
    // entry.date is from pageView docs this needs to be refactored to receive a param so the function knows the date field
    data.forEach((entry) => {
      let timestamp = entry.rank_date || entry.date;
      if (entry.type === "google_update") {
        timestamp = entry.date;
      }
      const timeFrameKey =
        Math.floor(timestamp / timeFrameInterval) * timeFrameInterval;

      // If the time frame is not in the objects, initialize the sum and count to 0
      if (!positionSumPerTimeFrame[timeFrameKey]) {
        positionSumPerTimeFrame[timeFrameKey] = 0;
        positionCountPerTimeFrame[timeFrameKey] = 0;
      }

      // Add the position to the sum and increase the count
      if (entry.type === "google_update") {
        positionSumPerTimeFrame[timeFrameKey] += entry.changes;
      } else {
        // TODO: entry.position is from page views this field needs to be set from param
        positionSumPerTimeFrame[timeFrameKey] +=
          entry.position || entry.pageViews;
      }
      positionCountPerTimeFrame[timeFrameKey]++;
    });

    console.log(`positionSumPerTimeFrame`);
    console.log(positionSumPerTimeFrame);

    // Calculate the average position per time frame
    const averagePositionPerTimeFrame = Object.keys(
      positionSumPerTimeFrame
    ).map((timeFrameKey) => {
      const averagePosition =
        positionSumPerTimeFrame[timeFrameKey] /
        positionCountPerTimeFrame[timeFrameKey];
      return {
        x: new Date(Number(timeFrameKey)).toLocaleDateString(),
        y: isNaN(averagePosition) ? null : Math.trunc(averagePosition),
      };
    });

    // Sort the results by date
    const sortedResults = averagePositionPerTimeFrame.sort(
      (a, b) => new Date(a.timeFrame) - new Date(b.timeFrame)
    );

    return sortedResults;
  }

  // const timeFrameUnit = "monthly"; // Change to "daily" or "monthly" as needed
  const result = calculateAveragePosition(data, timeFrameUnit);

  console.log(`calculateAveragePosition`);
  console.log(result);
  return result;

  // handle timeframe average grouping for weeks and months
  /*
   right now aggregation is merging many results into less data points,
    corresponding with with the thetimeframe unit, so merging a weeks data in to 1 data point for example.
  is there a way to avoid merging data, but still show only a subset of labels? with chartJS, it is possible, but ran into issues trying in the past.
  especially because we want to only show labels responsively, and prevent crowding.
  */

  const weeks = ["01", "07", "13", "19", "25", "30"];
  try {
    // object key for the date, x coordinate
    const date_key = axisKeys.x;
    //object key for the quantity, y coordinate
    const y_quantity_key = axisKeys.y;

    // Adam's Comment:  aggregatedData is an object, each key is a rank_date. each value is a an array of posiitons for that rank_date

    const groupedResult = data.reduce((groups, entry) => {
      let last_month;
      let parsedDate = parseDate ? parseDate(entry[date_key]) : entry[date_key];
      let date = moment(parsedDate);
      entry[date_key] = parsedDate;

      const isoMonth = ("0" + (date.month() + 1)).slice(-2);
      // Comment: groups are renamed according to time frame selected

      if (timeFrameUnit === "daily") {
        const formattedDate = date.format("YYYY-MM-DD");

        if (!groups[formattedDate]) {
          groups[formattedDate] = [];
        }
        groups[formattedDate].push(entry);
        return groups;
      } else if (timeFrameUnit == "weekly") {
        // set the month the current item falls into.
        if (!last_month) {
          last_month = date.month();
        } else {
          if (last_month < date.month()) {
            last_month = date.month();
          }
        }

        // get the week of the month the serp falls into, as a date of month

        let formattedDate;
        let currentIndx = 0;
        for (let i = 0; i < weeks.length; i++) {
          if (date.date() <= parseInt(weeks[i])) {
            break;
          }
          currentIndx = i;
        }
        formattedDate = `${date.year()}-${isoMonth}-${
          weeks[currentIndx]
        }T00:00`;

        formattedDate = new Date(formattedDate);
        if (!groups[formattedDate]) {
          groups[formattedDate] = [];
        }
        groups[formattedDate].push(entry);
        return groups;
      } else if (timeFrameUnit == "monthly") {
        // Comment: grouping by months is simple.
        const formattedDate = new Date(`${date.year()}-${isoMonth}-01`);
        if (!groups[formattedDate]) {
          groups[formattedDate] = [];
        }
        groups[formattedDate].push(entry);
        return groups;
      }

      return groups;
    }, {});

    // console.log(timeFrameUnit)
    // console.log('groupedResult', groupedResult)

    // from grouped object to aggregated & sorted array.

    const sortedResult = Object.keys(groupedResult)
      .map((key) => {
        // first item in array of results grouped by tiemframe, day, week, or month.
        const first = groupedResult[key][0];

        const result = groupedResult[key].reduce(
          (past, current) => {
            return {
              ...first,
              x: moment(key).toDate(),
              // average quantity for time period.
              //
              y:
                past[y_quantity_key] +
                current[y_quantity_key] / groupedResult[key].length,
            };
          },
          { [y_quantity_key]: 0 }
        );

        return result;
      })
      .sort((a, b) => a.x - b.x);

    console.log(`sortedResult`);
    console.log(sortedResult);

    return sortedResult;
  } catch (err) {
    console.error(err);
    return [];
  }
}

/**
 * - Group serp positions by date. dates are spaced daily, weekly, or monthly
 * @param {*} start
 * @param {*} end
 * @param {*} time
 * @param {*} data (serps)
 * @returns
 */
const aggregateSerpData = (start, end, time, serps) => {
  const data = serps;
  const timeFrameUnit = time;

  // finalAggData is dataset for chart
  const finalAggData = mapToChartData(data, timeFrameUnit, {
    x: "rank_date",
    y: "position",
  });

  console.log("finalAggData");
  console.log(finalAggData);

  // uneccessary for now, date range already set from server. or should be last and optional parameters, isntead fo first and required.
  /*
  if (start && end) {
    const avgData = finalAggData.filter((agg) => {
      const currentDate = new Date(agg.x);
      return start.toDate() <= currentDate && currentDate <= end.toDate();
    });
 
    return avgData;
  }
  */
  return finalAggData;
};

/**
 *
 * @param {*} Object - {serps, pageViews, startDate, endDate}
 * @returns Array { x: date, y: number }
 *
 */
function calculateAvgData({ serps = [], pageViews = [], startDate, endDate }) {
  const filteredSerps = [];
  let avgData = {
    total: 0,
    totalAvg: 0,
    p1Avg: 0,
    p2Avg: 0,
    p3Avg: 0,
    pageViews: 0,
  };

  // console.log(`pageViews before being processed `);
  // console.log(pageViews);

  function findAveragePosition(serps) {
    const sumOfPositions = serps.reduce((acc, serp) => acc + serp.position, 0);
    const averagePosition = (sumOfPositions / serps.length || 0).toFixed(2);

    // console.log(`findAveragePosition`);
    // console.log(serps);
    return averagePosition;
  }

  let totalPageViews;

  if (serps.length > 0) {
    serps.forEach((serp) => {
      // console.log('serp loop');
      // console.log(serp);
      if (
        moment(new Date(startDate.valueOf())).format("YYYY MM DD") <=
          moment(new Date(serp.rank_date).toString()).format("YYYY MM DD") &&
        moment(new Date(serp.rank_date).toString()).format("YYYY MM DD") <=
          moment(new Date(endDate.valueOf())).format("YYYY MM DD")
      ) {
        filteredSerps.push(serp);
      }
    });

    const p1Serps = filteredSerps.filter((serp) => serp.page === 1);
    const p2Serps = filteredSerps.filter((serp) => serp.page === 2);
    const p3Serps = filteredSerps.filter((serp) => serp.page === 3);
    // the sum of all serp positions
    let totalDailySum = 0;
    filteredSerps.forEach((serp) => (totalDailySum += serp.position));

    const totalAvg = (totalDailySum / filteredSerps.length || 0).toFixed(2);
    const p1Avg = findAveragePosition(p1Serps);
    const p2Avg = findAveragePosition(p2Serps);
    const p3Avg = findAveragePosition(p3Serps);

    totalPageViews = pageViews.reduce(
      (total, currentView) => total + currentView.pageViews,
      0
    );

    avgData = {
      total: totalDailySum,
      totalAvg,
      p1Avg,
      p2Avg,
      p3Avg,
      pageViews: totalPageViews,
    };
  }

  avgData.pageViews = totalPageViews;

  return avgData;
}

async function addSerps(data, websiteId) {
  const result = {
    successArr: [],
    failedArr: [],
    error: null,
  };
  for (let serpData of data) {
    // Format date the same way we store serps
    // TODO: Add error checking if date is not valid
    serpData.serp.rank_date = moment(
      new Date(serpData.serp.rank_date.seconds * 1000)
    ).format("YYYY-MM-DD");

    const keywordSnapshot = (
      await firebaseDbGateway.queryCollection(
        `websites/${websiteId}/keywords`,
        [["keyword", "==", serpData.serp.keyword]]
      )
    ).data;
    let serpSnap = (
      await firebaseDbGateway.queryCollection(
        `engines/${serpData.engineId}/serps`,
        [
          ["keyword", "==", serpData.serp.keyword],
          ["rank_date", "==", serpData.serp.rank_date],
        ]
      )
    ).data;

    if (serpSnap.length < 1) {
      result.successArr.push(serpData.serp.keyword);

      let keywordId;
      if (keywordSnapshot.length < 1) {
        keywordId = (
          await firebaseDbGateway.addDocument(
            `websites/${websiteId}/keywords`,
            { keyword: serpData.serp.keyword }
          )
        ).data.id;
      } else {
        keywordId = keywordSnapshot[0].id;
      }

      const serpResp = await firebaseDbGateway.addDocument(
        `engines/${serpData.engineId}/serps`,
        { ...serpData.serp, engineId: serpData.engineId, keywordId }
      );
    } else {
      result.failedArr.push(serpData.serp.keyword);
      result.error = "Duplicate Rankings found";
    }
  }

  return result;
}
async function getPageViews(websiteId, { startDate, endDate }) {
  const start = moment(startDate).startOf("day").toDate();
  const end = moment(endDate).endOf("day").toDate();

  console.log(`getPageViews ${start} - ${end}`);
  const queryData = (
    await firebaseDbGateway.queryCollection(
      "gaData",
      [
        ["websiteId", "==", websiteId],
        ["date", ">=", start],
        ["date", "<=", end],
      ],
      { orderBy: ["date", "desc"] }
    )
  ).data;
  // console.log("queryData");
  // console.log(queryData);
  return queryData;
}

async function getFilteredSiteMaps(websiteId, { startDate, endDate }) {
  const start = moment(startDate).startOf("day").toDate();
  const end = moment(endDate).endOf("day").toDate();

  const queryData = (
    await firebaseDbGateway.queryCollection(
      "filteredSitemaps",
      [
        ["websiteId", "==", websiteId],
        ["date", ">=", start],
        ["date", "<=", end],
      ],
      { orderBy: ["date", "desc"] }
    )
  ).data;

  const globalEvents = await db.collection("globalEvents").get();

  globalEvents.docs.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    // console.log(doc.id, " => ", doc.data());
    queryData.push(doc.data());
  });

  queryData.sort((a, b) => (a.date < b.date ? 1 : b.date < a.date ? -1 : 0));

  // debugger;

  return queryData;
}

function aggregatePageViewData(data, timeFrameUnit) {
  const aggregated = mapToChartData(
    data,
    timeFrameUnit,
    { x: "date", y: "pageViews" },
    function (firestoreDate) {
      // change to Date object if not already.
      return firestoreDate.toDate != undefined
        ? firestoreDate.toDate()
        : firestoreDate;
    }
  );

  return aggregated;
}

/**
 * Add parameters to events collection
 * @param {String} orgId Organization Id
 * @param {datetime} datetime Datetime stamp
 * @param {String} userId User Id from firebase
 * @param {String} title "Website Added"?
 * @param {String} body URL of the website
 */
export const eventsHelper = async ({
  orgId,
  datetime,
  userId,
  title,
  eventName,
  body,
}) => {
  const eventsData = {
    orgId,
    datetime,
    eventName,
    userId: userId ? userId : "",
    title: title ? title : "",
    body: body ? body : "",
  };
  try {
    await firebaseDbGateway.addDocument("events", eventsData);
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
  return eventsData;
};

/**
 *
 * @param {file} file
 * @param {string} websiteId
 * @param {string} orgId
 * @param {()} callback - triggers once file is successfully uploaded [DEPRECATED]
 * @returns {status, failedArr}
 */
async function saveRankings(
  selectedFile,
  file,
  websiteId,
  orgId,
  keywordsArr = [],
  selectedEngine
) {
  return new Promise(async (resolve, reject) => {
    const keywordsSet = new Set(keywordsArr);
    const fileName = file.name;
    const allLines = file.split(/\r\n|\n/);
    const failedSet = new Set();
    const successArr = [];
    const uploadData = [];
    const engines = (
      await firebaseDbGateway.queryCollection("engines", [
        ["websiteId", "==", websiteId],
      ])
    ).data;

    // console.log(`saveRankings is`)
    // console.log(selectedEngine);

    // console.log(engines);

    let columnHeaders = [];
    let failedUrl = 0;
    for (let i = 0; i < allLines.length; i++) {
      const line = allLines[i];
      console.log(`processing line`, line);
      if (i == 0) {
        // debugger;
        const cleanLine = line.replace(/\s/g, "");
        // this checks if the first row is empty
        if (cleanLine.length === 3) {
          reject(new Error("Missing header columns"));
        }

        const serpData = cleanLine.split(",");
        serpData.forEach((column) => columnHeaders.push(column));
      } else {
        const serp = {};

        const cleanLine = line.replace(/\s/g, "");

        // if length of the trimmed line is 3 due to empty values, continue to next row
        if (cleanLine.length === 3) {
          continue;
        }

        const serpData = line.split(",").map((text, idx) => {
          if (columnHeaders[idx] !== "keyword") {
            return text.trim().toLowerCase();
          }
          return text.trim();
        });

        for (let i = 0; i < columnHeaders.length; i++) {
          let data = serpData[i];

          // validation layer and converting data types
          if (columnHeaders[i] === "position") {
            data = parseInt(data);
          }
          serp[columnHeaders[i]] = data;
        }

        console.log(`serp is `);
        console.log(serp);

        if (serp.position && serp.keyword && serp.rank_date && serp.urlFound) {
          const serpEngine = engines.find((engine) => {
            return engine.location_code === selectedEngine.value;
          });

          if (serpEngine) {
            console.log(`serpEngine`, serpEngine);
            const validUrl = validateUrl(serp.urlFound);

            if (!validUrl) {
              failedUrl += 1;
              failedSet.add(serp.keyword);
            } else if (keywordsSet.has(serp.keyword)) {
              failedSet.add(serp.keyword);
            } else {
              successArr.push(serp.keyword);
              keywordsSet.add(serp.keyword);
              uploadData.push({ serp, engineId: serpEngine.id });
            }
          }
        } else {
          reject(
            new Error(
              "Missing columns detected. make sure to include the folllowing columns: keyword, rank_date, position, page, & urlFound"
            )
          );
        }
      }
    }

    if (failedUrl) {
      // soft fail for saving csv to cloud storage
      alert(
        new Error(
          "Failed to add rankings to cloud storage due to invalid url in csv"
        )
      );
    } else {
      try {
        await uploadToBucket(selectedFile, orgId, websiteId);
        // debugger;
      } catch (error) {
        reject(
          new Error(
            `Failed to add rankings to cloud storage due to ${error.message}`
          )
        );
      }
    }

    // debugger;
    try {
      checkLimits("keywords", orgId, successArr.length).then(
        async (response) => {
          if (!response.isAtLimit && response.incrementLimitsBy) {
            const result = await addSerps(uploadData, websiteId);

            result.failedArr.forEach((failedSerp) => {
              failedSet.add(failedSerp);
            });

            response.incrementLimitsBy(result.successArr.length);

            // TODO: Check eventsHelper function
            // const eventsData = {
            //   orgId,
            //   websiteId,
            //   datetime: firebase.firestore.FieldValue.serverTimestamp(),
            //   userId: firebase.auth().currentUser.uid,
            //   eventName: 'importRankings',
            //   title: 'Rankings Added',
            //   body: {
            //     failed: result.failedArr,
            //     success: result.successArr
            //   }
            // };
            // what's this for?
            // eventsHelper(eventsData);
            resolve({
              successArr: result.successArr,
              failedArr: Array.from(failedSet),
              error: result.error,
            });
          } else {
            reject(new Error("Keyword ranking limit reached"));
          }
        }
      );
    } catch (e) {
      reject(e);
    }
  });
}
/**
 * @param {object} file File{ url, orgId, websiteId }
 */
export async function uploadFile({ selectedFile, orgId, websiteId }) {
  try {
    const storage = firebaseDbGateway.getStorage();

    const storageRef = storage.ref();
    const orgRef = storageRef.child(orgId);
    const websiteRef = orgRef.child(websiteId);
    const fileRef = websiteRef.child(selectedFile.name);
    await fileRef.put(selectedFile);

    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
}
const uploadToBucket = async (selectedFile, orgId, websiteId) => {
  return await uploadFile({
    selectedFile,
    orgId,
    websiteId,
  });
};

async function getEngines(websiteId) {
  const engines = (
    await firebaseDbGateway.queryCollection("engines", [
      ["websiteId", "==", websiteId],
    ])
  ).data;

  // TODO: Improve speed, or have option to star favorite engine and query on field
  // const itemToFind = 2840;
  // const foundIdx = engines.findIndex(el => el.location_code == itemToFind)
  // if(foundIdx){
  //   const gUsa = engines.splice(foundIdx, 1);
  //   engines.unshift(gUsa[0]);
  // }
  return engines;
}

async function getKeywords(engineId) {
  const serps = (
    await firebaseDbGateway.queryCollection(`engines/${engineId}/serps`, [], {
      limit: 500,
    })
  ).data;

  return serps;
}

/**
 *
 * @param {*} oid
 * @returns websites, integration data and other
 */
async function getAllWebsiteKeywords(oid) {
  const queryOrg = (await getParentOrgId(oid)) || oid;

  console.log(`queryOrg is `, queryOrg);
  // const websites = (
  //   await firebaseDbGateway.queryCollection("websites", [
  //     ["orgId", "==", queryOrg],
  //   ])
  // ).data;

  const websites = await db
    .collection("websites")
    .where("orgId", "==", queryOrg)
    .get();
  const today = new Date();
  const yesterday = moment(today).subtract(1, "days").format("YYYY-MM-DD");
  const keywordSet = new Set();
  let keywords = [];

  for (const website of websites.docs) {
    const keywordsChangeMap = {};
    const websiteId = website.id;

    // per each website, get all engines then all the engines serps
    const engines = await db
      .collection("engines")
      .where("websiteId", "==", websiteId)
      .get();

    console.log(`Engines is ${engines.size}`);
    console.log(`Engines is ${engines.empty}`);
    console.log(engines.docs[0]);
    let serpsRaw = [];
    for (const engine of engines.docs) {
      const engineId = engine.id;
      const serpQuery = await db
        .collection(`engines/${engineId}/serps`)
        .orderBy("rank_date", "desc")
        .get();

      for (let serp of serpQuery.docs) {
        serpsRaw.push(serp.data());
        if (keywordsChangeMap[serp.keywordId]) {
          keywordsChangeMap[serp.data().keywordId].push(serp.data());
        } else {
          keywordsChangeMap[serp.data().keywordId] = [serp.data()];
        }
      }

      const filteredSerp = serpsRaw.filter(
        (serp) =>
          moment(new Date(serp.rank_date.seconds * 1000)).format(
            "YYYY-MM-DD"
          ) === yesterday && !keywordSet.has(serp.keywordId)
      );

      console.log(`keywordsChangeMap`, keywordsChangeMap);

      const serp = filteredSerp.map((serp) => {
        const keywordId = serp.keywordId;
        serp.id = keywordId;
        serp.url = serp.urlFound;
        serp.websiteId = websiteId;

        if (keywordsChangeMap[keywordId].length > 1) {
          serp.change =
            keywordsChangeMap[keywordId][1].position -
            keywordsChangeMap[keywordId][0].position;
        } else {
          serp.change = 0;
        }

        keywordSet.add(serp.id);
        return serp;
      });

      keywords = [...keywords, ...serp];
    }

    const keywordsArr = await db
      .collection(`websites/${websiteId}/keywords`)
      .get();

    for (const keyword of keywordsArr.docs) {
      if (!keywordSet.has(keyword.id)) {
        keywords.push({ ...keyword, url: website.url, websiteId, change: 0 });
      }
    }
  }

  return keywords;
}

/**
 * @param {String} orgId org id
 * @param {Array} keywords
 */
async function deleteKeywords(orgId, keywords) {
  try {
    const keywordsLength = keywords.length; 

    for (const keyword of keywords) {

      db.collection(`websites/${keyword.websiteId}/keywords`).doc(keyword.id).delete();

      await decrementFeatureLimit(orgId, 'keywords');

      const engines = await db
        .collection('engines')
        .where('websiteId', '==', keyword.websiteId)
        .get()
        .then(querySnap => {
          const data = [];
          querySnap.forEach(doc => {
            data.push({ id: doc.id });
          })
          return data;
      });

      for (const engine of engines) {
        const serps = await db
          .collection(`engines/${engine.id}/serps`)
          .where('keywordId', '==', keyword.id)
          .get()
          .then(querySnap => {
              const data = [];
              querySnap.forEach(doc => {
                  data.push({
                      id: doc.id
                  })
              })
            return data;
        });

        for (const serp of serps) { 
          db.collection(`engines/${engine.id}/serps`).doc(serp.id).delete();
        }
      }

      console.log(keyword);

      const tmpSerchRef = await db.collection('tempSearchAdKeywords').where('keyword', '==', keyword.keyword).get();

      if (!tmpSerchRef.empty) {
        console.log(`temp search ad keyword exists ${tmpSerchRef.docs[0].id} deleeting...`);
        await db.collection(`tempSearchAdKeywords`).doc(tmpSerchRef.docs[0].id).delete();
      }
      console.log(tmpSerchRef);

    }

    return { success: true, message: "keywords deleted success fully" };
  } catch (error) {
    return { success: false, error: error.message };
  }
}

/**
 * @param {Array} keywords
 */
async function addKeywords(keyword, orgId, token, parentOrgId, selectWebId) {
  // const response = await httpGateway.post(
  //   getAbsoluteURL("/api/keywords/create"),
  //   { ...keyword, orgId, parentOrgId },
  //   token
  // );
  // const data = response.data;
  // return data;
  console.log(`addKeywords`);
  
  let keywords = keyword.keyword.split(/\r?\n/);
  keywords = keywords.map(keyword => keyword.trim());
  keywords = keywords.filter(keyword => keyword !== "");

  console.log(`Request verified`);
  console.log(`keywords to add are`, keywords);
  // debugger;
  const { success, limits, message } = await getCurrentFeatureLimits(parentOrgId);

  console.log(`current limits are`, limits);
  // const limits = await db
  //   .collection('limits')
  //   .where('orgId', '==', orgId)
  //   .get()
  //   .then(querySnap => {
  //     const limit = [];
  //     querySnap.forEach(doc => {
  //         limit.push({ id:doc.id, ...doc.data()});
  //     })
  //     return limit;
  // });

  try {

    const batch = db.batch();
    const websitesRef = db.collection("websites").doc(selectWebId);
    const keywordsRef = websitesRef.collection("keywords");
    try {
      keywords.forEach(async keyword => {
        const newDocRef = keywordsRef.doc(); // Automatically generates a new document ID
        batch.set(newDocRef, { keyword, orgId, dateAdded: new Date() });
        const { canProceed, message } = await checkFeatureLimit(
          parentOrgId,
          "keywords"
        );

        console.log(`canProceed is ${canProceed}`)
      });

      // const { success, limits, message } = await getCurrentFeatureLimits(parentOrgId);
      // console.log(`new limits.keywords_count after addinng`)
      // console.log(limits.keywords_count);

      await batch.commit();

      return { success: true, message: "keyword added successfully" };

    } catch (error) {
      return { success: false, error: error.message };
    }



    // TODO: Post mettric to datadog
    // const totalKeywords = await getTotalKeywords();
    // await datadogMetric('ranktivity.keywords.total', Math.floor((new Date()).getTime() / 1000), totalKeywords);


    // return res.status(200).json({ 'totalKeywords': totalKeywords })
  } catch (error) {
    return { success: false, error: error.message };
  }

}

async function getAllWebsites(oid) {
  const websites = (
    await firebaseDbGateway.queryCollection("websites", [["orgId", "==", oid]])
  ).data;

  return websites;
}

async function deleteWebsite(websiteId, oid) {
  try {
    // wait for primary docs to be set to deleted, to emmediately soft delete and remove from UI, finish recursive delete for later.
    await firebase.firestore().doc(`websites/${websiteId}`).update({
      isDeleted: true,
    });

    // debugger;
    const parentOrgId = await getParentOrgId(oid)
    await decrementFeatureLimit(parentOrgId, "domains");

    // remove website url from competitor docs.
    await firebaseDbGateway
      .queryCollection("competitors", [["websiteId", "==", websiteId]])
      .then(({ data }) => {
        data.forEach((doc) => {
          firebaseDbGateway.updateDocument("competitors", doc.id, {
            websiteName: "",
            websiteId: "",
          });
        });
      });

    // these are just first level documents, no need time consuming, defered recursive deletion.
    // if in future this turns out to be slow because of too many such documents, extract to function / scheduled function.

    await firebaseDbGateway.deleteDocumentsFromCollection(
      "alerts",
      "keyword.website.id",
      websiteId
    );

    getEngines(websiteId).then((engines) => {
      engines.forEach(async (engine) => {
        await firebaseDbGateway.deleteDocumentFromCollection(
          "engines",
          engine.id
        );
      });
    });

    firebaseDbGateway.deleteDocumentsFromCollection(
      GA_DATA,
      "websiteId",
      websiteId
    );

    firebaseDbGateway.deleteSubcollectionFromCollection(
      WEBSITES,
      websiteId,
      "keywords"
    );
    firebaseDbGateway.deleteDocumentFromCollection(WEBSITES, websiteId);
  } catch (err) {
    console.error(err);
  }

  return {
    success: true,
  };
}

/**
 *
 * @param {*} param0
 * @param {*} timeFrameUnit
 * @returns  {string[]} - filtered labels
 */

function labelPresenter({ startDate, endDate }, timeFrameUnit) {
  const resultList = [];

  const start = moment(startDate);
  const end = moment(endDate);
  const daysInRange = end.diff(start, "days");

  let months = [];
  let lastIndex = daysInRange;

  for (let i = 0; i <= daysInRange; i++) {
    let index = i;
    const currentItem = start;

    const date = moment(currentItem).format("MMM DD");
    const monthOfYear = moment(currentItem).format("MM/yyyy");
    // whether to show tooltip or not. boolean expressions for whether to show labels.
    const showEveryOtherDay = (index + 1) % 2 !== 0;
    const showDaysMonthly = daysInRange > 31;
    const showMonthly = months.indexOf(monthOfYear) === -1;

    // if it is not in the months, add it.
    if (showMonthly) {
      months.push(monthOfYear);
    }

    switch (timeFrameUnit) {
      case "daily": {
        if (showDaysMonthly && showMonthly) {
          resultList.push(date);
        } else if (showEveryOtherDay || index == lastIndex) {
          resultList.push(date);
        } else {
          resultList.push(null);
        }
        break;
      }
      case "weekly": {
        if (index % 7 == 0 || index == lastIndex) {
          resultList.push(date);
        } else {
          resultList.push(null);
        }

        break;
      }
      case "monthly": {
        if (showMonthly || index == lastIndex) {
          resultList.push(date);
        } else {
          resultList.push(null);
        }
        break;
      }
    }

    start.add(1, "day");
  }
  return resultList;
}

async function getAllKeywordTags(orgId) {
  return (await firebaseDbGateway.queryCollection(`orgs/${orgId}/tags`, [], {}))
    .data;
}

function saveNewTags(orgId, tags) {
  tags.forEach((rawTag) => {
    const tag = { tag: rawTag.label.trim() };
    firebaseDbGateway.updateDocument(
      `orgs/${orgId}/tags`,
      rawTag.value.toLowerCase(),
      tag
    );
  });
}

async function addTags(websiteId, keywords, newTags) {
  try {
    for (let keyword of keywords) {
      const keywordId = keyword.keywordId;
      let tags = new Set([...keyword.tags, ...newTags]);
      tags = [...tags];
      await firebaseDbGateway.updateDocument(
        `websites/${websiteId}/keywords`,
        keywordId,
        { tags }
      );
    }
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
}

const checkBillingUnpaid = async (orgId) => {
  const billing = (
    await firebaseDbGateway.queryCollection(`orgs/${orgId}/subscriptions`)
  ).data[0];

  return billing?.status === "unpaid" || billing?.status === "canceled";
};

const checkKeywordsAndEngines = async (docId, parentOrgId) => {
  let keywordTotal = 0;
  let enginesTotal = 0;

  try {
    const orgDocSnap = await db.collection("orgs").doc(parentOrgId).get();
    const orgData = { ...orgDocSnap.data(), id: docId };
    const { max_keywords } = orgData.planFeatures;

    const websitesSnapshot = await db
      .collection("websites")
      .where("orgId", "==", docId)
      .get();

    const promises = websitesSnapshot.docs.map(async (websiteDoc) => {
      const keywordRef = await db
        .collection(`websites/${websiteDoc.id}/keywords`)
        .get();
      const enginesSnapshot = await db
        .collection("engines")
        .where("websiteId", "==", websiteDoc.id)
        .get();
      keywordTotal += keywordRef.size;
      enginesTotal += enginesSnapshot.size;
    });

    return Promise.all(promises)
      .then(() => {
        return {
          maxKeyword: max_keywords,
          keywordTotal,
          enginesTotal,
          success: true,
        };
      })
      .catch((error) => {
        return {
          success: false,
          error,
        };
      });
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export {
  getWebsiteSerps,
  getPageViews,
  getFilteredSiteMaps,
  aggregateSerpData,
  aggregatePageViewData,
  calculateAvgData,
  saveRankings,
  uploadToBucket,
  getEngines,
  getKeywords,
  deleteKeywords,
  addKeywords,
  getAllWebsiteUniqueKeywords,
  getAllWebsiteKeywords,
  getAllWebsites,
  deleteWebsite,
  mapToChartData,
  labelPresenter,
  getAllKeywordTags,
  saveNewTags,
  addTags,
  checkBillingUnpaid,
  getNotes,
  checkKeywordsAndEngines,
};
